import { breakpointXsMax, breakpointSmMax, breakpointMdMax, breakpointLgMax } from '@mediahuis/chameleon-theme-wl/tokens';

import { type Viewport, viewPorts } from '@hubcms/domain-styling';
import type { ImageSizes } from '@hubcms/domain-images';

export const createImageSizes = (sizes: ImageSizes = {}) => {
  const breakpoints: Record<Viewport, string | null> = {
    xs: breakpointXsMax,
    sm: breakpointSmMax,
    md: breakpointMdMax,
    lg: breakpointLgMax,
    xl: null,
  };

  const mediaSizes = viewPorts
    .filter(viewport => Object.hasOwn(sizes, viewport))
    .map((viewPort, idx, arr) => {
      if (idx === arr.length - 1) {
        return `${sizes[viewPort]}`;
      }
      return `(max-width: ${breakpoints[viewPort]}) ${sizes[viewPort]}`;
    });

  return mediaSizes.join(', ');
};
