import { Fragment } from 'react';
import cx from 'classnames';

import { createImageSizes, createImageSrcSet } from '@hubcms/utils-images';

import type { ImageElementProps } from './types';
import styles from './image-element.module.scss';

const ImageElement = ({
  className = '',
  src,
  alt,
  priority = false,
  sizes,
  aspectRatio,
  layout = '',
  imageRef,
  onClick,
  srcSetConstraints,
  ...otherProps
}: ImageElementProps) => {
  const Wrapper = onClick ? 'button' : Fragment;
  const wrapperProps = onClick ? { onClick, className: styles.buttonWrapper } : null;

  return (
    // <img> is not an interactive element, so wrapping this with an unstyled button if there is an onClick event
    // omit the wrapper if there is no onClick event, the component should not be focusable
    <Wrapper {...wrapperProps}>
      <img
        className={cx(styles.imageElement, className, aspectRatio, styles[layout])}
        src={src}
        srcSet={createImageSrcSet(src, srcSetConstraints)}
        alt={alt}
        sizes={createImageSizes(sizes)}
        loading={priority ? 'eager' : 'lazy'}
        ref={imageRef}
        {...otherProps}
      />
    </Wrapper>
  );
};

export default ImageElement;
