import { type Variant, variantPattern } from '@hubcms/domain-images';

export const getVariant = (src: string): Variant | undefined => {
  const variantString = variantPattern.exec(src);

  if (variantString) {
    return variantString[1]?.toLowerCase() as Variant;
  }

  return undefined;
};
